import React from "react"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Theme } from "@mui/material"
import { ChangeOrderActionCode } from "../types/action"

const submittedBackground = "#cd9534"
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[900],
    border: "none",
    borderRadius: 5,
    color: "white",
    fontWeight: 900,
    fontSize: 13,
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  draft: {
    background: theme.palette.grey[600],
  },
  submitted: {
    background: submittedBackground,
  },
  approve: {
    background: "#45bf55",
  },
  revise: {
    background: "#b96aba",
  },
}))

export default function ChangeOrderAction(props: { code: ChangeOrderActionCode }) {
  const { t } = useTranslation("private")
  const company = useSelector((state: any) => state.company)

  const classes = useStyles()
  const className = props.code

  return (
    <Box className={`${classes.root} ${classes[className]}`}>
      <Typography>{t(`view.ChangeOrderAction.${props.code}.${company.company_type}`)}</Typography>
    </Box>
  )
}
