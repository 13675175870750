import {convertToNumber} from "../util/number"
import {CreateDailyInitialValues, CreateDailyOutputForApi} from './CreateDaily.types'
import {
  convertEquipmentBreakdown,
  convertLaborBreakdown, convertMaterialBreakdown,
  generateMarkupData,
  generateSubject,
} from './CreateTicket.submit'


export function convertFormValuesToApiData(
  values: CreateDailyInitialValues,
  nextDailyNumber: string|null,
): CreateDailyOutputForApi {
  // Set up most of our values
  const submitValues: any = Object.keys(values)
    .filter(
      (key) =>
        !(
          key.includes("Breakdown") ||
          key.includes("Markup") ||
          key.includes("files") ||
          key === "isAddCosts" ||
          key === "isLumpSum"
          // @ts-ignore
        ) && values[key]
    )
    .reduce((current, key) => {
      // @ts-ignore
      return Object.assign(current, { [key]: values[key] })
    }, {})
  // auto-generate subject
  submitValues.subject = generateSubject(submitValues)
  // Next number
  submitValues.number = values.number ? values.number.toString() : nextDailyNumber
  // initialize markup
  submitValues.markup = []
  // Set up the cost related stuff
  submitValues.includes_costs = values.isAddCosts
  submitValues.is_lump_sum = values.isLumpSum
  if (submitValues.is_lump_sum) {
    const lumpSumTotal = convertToNumber(values.manual_total)
    submitValues.lump_sum_total = lumpSumTotal == null ? 0 : lumpSumTotal
  }

  // Start and end dates
  const startDate = new Date(values.date_start)
  submitValues.date_start = startDate.toISOString()
  if (values.date_end) {
    const endDate = new Date(values.date_end)
    submitValues.date_end = endDate.toISOString()
  } else {
    delete submitValues.date_end
  }

  // Equipment breakdown and markups
  if (values.equipmentBreakdown.length) {
    submitValues.equipment = values.equipmentBreakdown.map(convertEquipmentBreakdown)
    if (values.type === "sum_rates") {
      submitValues.markup = submitValues.markup.concat(
        generateMarkupData(values.equipmentMarkup, 'equipment')
      )
    }
  }
  // Labor breakdown and markups
  if (values.laborBreakdown.length) {
    submitValues.labor = values.laborBreakdown.map(convertLaborBreakdown)
    if (values.type === "sum_rates") {
      submitValues.markup = submitValues.markup.concat(
        generateMarkupData(values.laborMarkup, 'labor')
      )
    }
  }
  // Material breakdown and markups
  if (values.materialBreakdown.length) {
    submitValues.material = values.materialBreakdown.map(convertMaterialBreakdown)
    if (values.type === "sum_rates") {
      submitValues.markup = submitValues.markup.concat(
        generateMarkupData(values.materialMarkup, 'material')
      )
    }
  }
  // Ticket markups
  if (values.formMarkup.length) {
    submitValues.markup = submitValues.markup.concat(
      generateMarkupData(values.formMarkup, 'daily')
    )
  }
  return submitValues
}
