import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Typography from '@mui/material/Typography'
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'

// @ts-ignore
import Container from "../../../../../components/Container"
import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import CreateDaily from '../../../../../forms/CreateDaily'
import {CreateDailyInitialValues} from '../../../../../forms/CreateDaily.types'
import {convertFormValuesToApiData} from '../../../../../forms/CreateDaily.submit'
import {goToSingleDaily,} from '../../../../../util/routes'
import {createDaily, uploadDailyFiles} from '../../../../../api/daily'
import {addDaily} from '../../../../../store/features/dailySlice'


export default function CreateDailyView(): JSX.Element {
  const { t } = useTranslation("private")
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const history = useHistory()
  const dispatch = useDispatch()
  const { projectId } = useParams<{projectId: string}>()
  const project = useTracFloSelector((state) => state.project)

  const submit = async (values: CreateDailyInitialValues, nextDailyNumber: string | null) => {
    try {
      const res = await createDaily(
        convertFormValuesToApiData(values, nextDailyNumber),
        projectId
      )
      if (res.status >= 400 || !res?.data?.id) {
        enqueueSnackbar(t("view.Daily.CreateDaily.requestFailed"), {variant: "error",})
      }
      const daily = res.data
      enqueueSnackbar(t("view.Daily.CreateDaily.requestSucceeded"), {variant: "success",})
      dispatch(addDaily(daily))
      // upload files if we have them
      if (values.files.length > 0 && !!values.files[0].name) {
        const uploadingKey = enqueueSnackbar(t("Frequent.uploadingFiles"), {variant: 'info'})
        const fileUploadMessage = await uploadDailyFiles(values.files, project.id, daily.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      history.push(goToSingleDaily(project.id, daily.id))
    } catch(_) {
      enqueueSnackbar(t("view.Daily.CreateDaily.requestFailed"), {variant: "error",})
    }
  }

  return project && project.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t("view.Daily.CreateDaily.header")}</Typography>
      </Container>

      <CreateDaily submitValues={submit} project={project}/>
    </>
  ) : (
    <></>
  )
}

