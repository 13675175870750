import React from "react"
import {GridColDef} from "@mui/x-data-grid"

import {goToSingleDaily} from "../../../../../../util/routes"
import {formatMoney} from '../../../../../../libs/format'
import FormatTableTitleLink from '../../../../../../components/FormatTableTitleLink'
import { Daily } from "../../../../../../types/daily"


export default function AttachedDailyListColumns (
  projectId: string,
  t: (s: string) => string,
): GridColDef<Daily>[] {
  return [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 110,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedDailySubjectDailyHeader"),
      width: 250,
      editable: false,
      renderCell: (params) => {
        return (
          <FormatTableTitleLink
            url={goToSingleDaily(projectId, params.row.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedDailyTotalDailyHeader"),
      width: 150,
      editable: false,
      renderCell: (params) => {
        // daily reports are only shown to trades, total should always be shown
        return formatMoney(params.value ?? 0)
      },
    },
  ]
}
