import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Theme } from "@mui/material"

import { EstimateActionCode } from "../types/action"


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[900],
    border: "none",
    borderRadius: 5,
    color: "white",
    fontWeight: 900,
    fontSize: 13,
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  pending: {
    background: theme.palette.grey[600],
  },
  draft: {
    background: theme.palette.grey[600],
  },
  submitted: {
    background: "#f2c746",
  },
  revise: {
    background: "#b96aba",
  },
  gc_approved: {
    background: "#45bf55",
  },
  tm_authorized: {
    background: "#143366",
    opacity: "0.4",
  },
  not_needed: {
    background: "#FFFFFF",
    border: "solid 2px",
    borderColor: "#516487",
    color: "#687997",
  },
}))

interface EstimateActionProps {
  code: EstimateActionCode
}

export default function EstimateAction({code}: EstimateActionProps) {
  const { t } = useTranslation("private")
  const classes = useStyles()
  const className = code

  return (
    <Box className={`${classes.root} ${classes[className]}`}>
      <Typography>{t(`component.EstimateAction.${code}`)}</Typography>
    </Box>
  )
}
