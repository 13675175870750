import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import {useHistory, useParams} from "react-router-dom"
import { useDispatch } from "react-redux"
import {useSnackbar} from "notistack"

// @ts-ignore
import Container from "../../../../../components/Container"
import {initializeFormWithTicketData} from "../EditTicket/EditTicket.data"
import {Ticket, LaborBreakdown, TicketMarkup, EquipmentBreakdown, MaterialBreakdown} from "../../../../../types/ticket"
import {
  editTicketCosts,
  getByIdTicket, getTicketEquipmentBreakdown,
  getTicketFiles,
  getTicketLaborBreakdown,
  getTicketMarkup, getTicketMaterialBreakdown, uploadTicketFiles
} from "../../../../../api/ticket"
import {CreateTicketInitialValues} from "../../../../../forms/CreateTicket.types"
import {convertFormValuesToApiData} from "../../../../../forms/CreateTicket.submit"
import { updateTicket } from "../../../../../store/features/ticketsSlice"
import CostEditTicketForm from "./CostEditTicket.form"
import { goToSingleTicket } from "../../../../../util/routes"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { getMaterialTypes } from "../../../../../store/features/materialSlice"
import { getEquipmentTypes } from "../../../../../store/features/equipmentSlice"

export default function CostEditTicket() {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const history = useHistory()
  const { ticketId, projectId } = useParams<{ticketId: string, projectId: string}>()
  const project = useTracFloSelector((state) => state.project)
  const materialTypes = useTracFloSelector(getMaterialTypes)
  const equipmentTypes = useTracFloSelector(getEquipmentTypes)
  const [ticket, setTicket] = useState<Ticket | null>(null)
  const [labor, setLabor] = useState<LaborBreakdown[] | null>(null)
  const [equipment, setEquipment] = useState<EquipmentBreakdown[] | null>(null)
  const [material, setMaterial] = useState<MaterialBreakdown[] | null>(null)
  const [markup, setMarkup] = useState<TicketMarkup[] | null>(null)
  const [files, setFiles] = useState<any[] | null>(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // NOTE: this needs to be updated if more ticket-related states are added
  const readyToInitialize: boolean = ticket != null && labor != null && markup != null && files != null
    && project != null && equipment != null && material != null

  const submitTicket = async (values: CreateTicketInitialValues) => {
    try {
      const { data } = await editTicketCosts(
        convertFormValuesToApiData(values, ''),
        ticketId,
        projectId
      )
      if (data.id) {
        const updatedTicket = data
        enqueueSnackbar("Ticket Updated", {
          variant: "success",
        })
        dispatch(updateTicket(updatedTicket))
        // need to check that files actually exist
        if (values.files.length > 0 && !!values.files[0].name) {
          const uploadingKey = enqueueSnackbar('Uploading files...', {variant: 'info'})
          const fileUploadMessage = await uploadTicketFiles(values.files, project.id, updatedTicket.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        history.push(goToSingleTicket(project.id, updatedTicket.id))
      } else {
        enqueueSnackbar("There was a problem editing the ticket, code: 104", {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar("There was a problem editing the ticket, code: 102", {
        variant: "error",
      })
    }
  }

  // Get the ticket info we need to populate the form
  useEffect(() => {
    getByIdTicket(projectId, ticketId).then(
      (data: any) => {
        if (data?.id) {
          // we need to set include costs to true
          data.includes_costs = true
          setTicket(data)
        }
      }
    )

    getTicketMarkup(projectId, ticketId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setMarkup(res.data)
        }
      }
    )

    getTicketLaborBreakdown(projectId, ticketId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setLabor(res.data)
        }
      }
    )

    getTicketEquipmentBreakdown(projectId, ticketId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setEquipment(res.data)
        }
      }
    )

    getTicketMaterialBreakdown(projectId, ticketId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setMaterial(res.data)
        }
      }
    )

    getTicketFiles(projectId, ticketId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setFiles(res.data)
        }
      }
    )
  }, [projectId, ticketId])

  return project?.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t("view.Tickets.editCostsTicket")}</Typography>
      </Container>

      { readyToInitialize
        ? <CostEditTicketForm
          initialValues={
            initializeFormWithTicketData(
              ticket as Ticket,
              markup as TicketMarkup[],
              labor as LaborBreakdown[],
              material as MaterialBreakdown[],
              equipment as EquipmentBreakdown[],
              materialTypes,
              equipmentTypes,
            )
          }
          submitValues={submitTicket}
          existingFiles={files as any[]}
          project = {project}
        /> : <></>
      }
    </>
  ) : <></>
}
