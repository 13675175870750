import * as React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Grid, Typography } from "@mui/material"
import {GridFilterItem, GridFilterModel, GridLinkOperator} from "@mui/x-data-grid"

// @ts-ignore
import Container from "../../../../../components/Container"
import MetricCard from "../../../../../components/MetricCard"
import { getOpenItemsTickets } from "../../../../../api/ticket"
import { getOpenItemsChangeOrders } from "../../../../../api/changeOrder"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { goToChangeOrdersList, goToTicketsList } from "../../../../../util/routes"


export default function SubOverview() {
  const { t } = useTranslation("private")
  const history = useHistory<{filter: GridFilterModel}>()
  const { projectId } = useParams<{ projectId: string }>()
  const project = useTracFloSelector((state) => state.project)
  const [openItemsTickets, setOpenItemsTickets] = React.useState<string[]>([])
  const [openItemsChangeOrders, setOpenItemsChangeOrders] = React.useState<string[]>([])

  React.useEffect(() => {
    if (projectId && project && project.id === projectId) {
      getOpenItemsTickets(projectId).then(async (res) => {
        if (res.data) {
          setOpenItemsTickets(res.data)
        }
      })
      getOpenItemsChangeOrders(projectId).then(async (res) => {
        if (res.data) {
          setOpenItemsChangeOrders(res.data)
        }
      })
    }
  }, [projectId, project])

  const openItemsTicketsFilter: GridFilterItem[] = [
    {
      columnField: 'id',
      operatorValue: 'isAnyOf',
      value: openItemsTickets
    },
  ]

  const openItemsChangeOrdersFilter: GridFilterItem[] = [
    {
      columnField: 'id',
      operatorValue: 'isAnyOf',
      value: openItemsChangeOrders
    },
  ]

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" sx={{color: "#000000", opacity: "87%"}}>
              {t("view.Dashboard.yourOpenItems")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MetricCard
                  title={t("view.Dashboard.SubCardTitles.tix")}
                  large_number={openItemsTickets.length.toString()}
                  onClick={() => {
                    history.push(
                      goToTicketsList(projectId),
                      {filter: {items: openItemsTicketsFilter, linkOperator: GridLinkOperator.And}}
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MetricCard
                  title={t("view.Dashboard.SubCardTitles.cos")}
                  large_number={openItemsChangeOrders.length.toString()}
                  onClick={() => {
                    history.push(
                      goToChangeOrdersList(projectId),
                      {filter: {items: openItemsChangeOrdersFilter, linkOperator: GridLinkOperator.And}}
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
