import React, {useState, useEffect} from "react"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { getRateTypesFromLaborType, getRateTypeName } from "../libs/rateTypes"
import {convertToNumber} from "../util/number"
import { TicketDates } from "../types/ticket"
import { defaultAddLaborBreakdownInitialValues } from "./AddLaborToBreakdown.types"
import { Labor, LaborRateTypes } from "../types/labor"
import { ListOption } from "../types/form"
import AddLaborToBreakdownForm from './AddLaborToBreakdown.Form'


type AddLaborToBreakdownProps = {
  addRates: boolean,
  data: any,
  dropdownItems: Labor[],
  setData: (field: string, value: any) => void,
  setFormActive: (formActive: boolean) => void,
  ticketDates: TicketDates,
}

export default function AddLaborToBreakdown({
  addRates,
  data,
  dropdownItems,
  setData,
  setFormActive,
  ticketDates,
}: AddLaborToBreakdownProps): JSX.Element {
  const date_start = ticketDates.date_start ? new Date(ticketDates.date_start) : undefined
  const date_end = ticketDates.date_end ? new Date(ticketDates.date_end) : undefined
  const { t } = useTranslation("private")
  const [rateTypeOptions, setRateTypeOptions] = useState<ListOption<LaborRateTypes>[]>([])
  const [selectedType, setSelectedType] = useState<Labor | null>(null)

  // When we update the selected type, we need to make some changes to rate type
  useEffect(() => {
    setRateTypeOptions(getRateTypesFromLaborType(selectedType))
  }, [selectedType])

  return (
    <div>
      <Formik
        initialValues={defaultAddLaborBreakdownInitialValues(date_start)}
        validationSchema={
          Yup.object().shape({
            type_id: Yup.string().required(t("form.message.type_idRequired")),
            quantity: Yup.number()
              .required(t("form.message.headcountRequired"))
              .moreThan(0, t("form.message.greaterThanZero"))
              .integer(t("form.message.headcountInteger")),
            hours: Yup.string().required(t("form.message.hoursRequired")),
            rate_type: Yup.string().required(t("form.message.rateRequired")),
          })
        }
        onSubmit={(values, { resetForm }) => {
          // set up a bunch of fields we need for displaying
          if (selectedType == null) return
          values.type_name = selectedType.name
          values.cost_code = selectedType.cost_code ?? ""
          values.rate_type_name = getRateTypeName(values.rate_type) ?? ""
          values.total_hours = (convertToNumber(values.hours) ?? 0) * (convertToNumber(values.quantity) ?? 0)
          values.total_cost = (convertToNumber(values.total_hours) ?? 0) * (convertToNumber(values.rate) ?? 0)
          // then send it to parent
          setData("laborBreakdown", [...data, { ...values }])
          resetForm()
          setFormActive(false)
        }}
      >
        {(formikProps) =>
          <AddLaborToBreakdownForm
            {...formikProps}
            t={t}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            date_start={date_start}
            date_end={date_end}
            setFormActive={setFormActive}
            addRates={addRates}
            laborTypes={dropdownItems}
            rateTypeOptions={rateTypeOptions}
          />
        }
      </Formik>
    </div>
  )
}
