import React from "react"
import Box from "@mui/material/Box"
import { Theme, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useTranslation } from "react-i18next"

import { useTracFloSelector } from "../store/useTracFloSelector"
import { ActionCode } from "../types/action"


const submittedBackground = "#cd9534"
const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      background: theme.palette.grey[900],
      border: "none",
      borderRadius: 5,
      color: "white",
      fontWeight: 900,
      fontSize: 13,
      padding: theme.spacing(1),
      textAlign: "center",
      width: "100%",
    },
    co_attached: {
      background: "#143366",
      opacity: "0.4",
    },
    draft: {
      background: theme.palette.grey[600],
    },
    tm_submitted: {
      background: "#f2c746",
    },
    tm_approve: {
      background: "#6aacba",
    },
    submitted: {
      background: submittedBackground,
    },
    approve: {
      background: "#45bf55",
    },
    reject: {
      background: "#d92929",
    },
    revise: {
      background: "#b96aba",
    },
    reviewing: {
      background: submittedBackground,
      position: "relative",
      "& span": {
        display: "block",
        position: "absolute",
        right: 7,
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
      },
      "&:before": {
        background: theme.palette.common.black,
        content: "",
        display: "block",
        height: "100%",
        left: 0,
        opacity: 0.1,
        position: "absolute",
        top: 0,
        width: 0,
      },
    },
    reviewed: {
      "&:before": {
        width: "50%",
      },
    },
    owner: {
      "&:before": {
        width: "75%",
      },
    },
  }
})

export default function Action(props: { code: ActionCode, coAttached?: boolean }) {
  const { t } = useTranslation("private")
  const company = useTracFloSelector((state) => state.company)
  const ticketAction = props.coAttached
    ? t(`view.TicketAction.attached_to_co`)
    : t(`view.TicketAction.${props.code}.${company.company_type}`)
  const classes = useStyles()
  // use same formatting for similar actions
  const className = props.coAttached
    ? "co_attached"
    : props.code !== "tm_submitted" && props.code !== "tm_approve"
    ? props.code.replace("tm_", "").replace("cost_", "")
    : props.code

  return (
    <Box
      className={`${classes.root} ${
        ["reviewed", "owner"].includes(className) ? classes.reviewing : ""
      } ${classes[className]}`}
    >
      <Typography>{ticketAction}</Typography>
    </Box>
  )
}
