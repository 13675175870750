import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"
import {Daily, DailyLaborTimesheetReport} from '../types/daily'
import {uploadFiles} from './file'
import { File } from '../types/file'
import { HistoryItem } from '../types/history'
import {
  EquipmentBreakdown,
  LaborBreakdown,
  MaterialBreakdown,
  TicketMarkup,
} from '../types/ticket'


export async function getNextDailyNumber(project_id: string): Promise<AxiosResponse<{next_number: string}>> {
  return api({
    method: "get",
    url: `/project/${project_id}/next_daily_number`,
  })
}

export async function createDaily(daily: any, projectId: string,): Promise<AxiosResponse<Daily>> {
  return api({method: 'post', url: `/project/${projectId}/daily`, data: daily,})
}

export async function uploadDailyFiles(
  files: any[],
  project_id: string,
  daily_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, daily_id, 'daily')
}

export async function getDailyById(project_id: string, daily_id: string): Promise<AxiosResponse<Daily[]>> {
  return api({method: 'get', url: `/project/${project_id}/daily/${daily_id}`})
}

export async function getDailyFiles(project_id: string, daily_id: string): Promise<AxiosResponse<File[]>> {
  return api({method: "get", url: `/project/${project_id}/daily/${daily_id}/file_presigned`,})
}

export async function getDailyLaborBreakdown(project_id: string, daily_id: string): Promise<AxiosResponse<LaborBreakdown[]>> {
  return api({method: "get", url: `/project/${project_id}/daily/${daily_id}/labor_breakdown`})
}

export async function getDailyHistory(project_id: string, daily_id: string): Promise<AxiosResponse<HistoryItem[]>> {
  return api({method: "get", url: `/project/${project_id}/daily/${daily_id}/history`})
}

export async function editDaily(
  data: any,
  dailyId: string,
  projectId: string,
): Promise<AxiosResponse<Daily>> {
  return api({
    method: "patch",
    url: `/project/${projectId}/daily/${dailyId}`,
    data,
  })
}

export async function getDailyMaterialBreakdown(
  project_id: string,
  daily_id: string
): Promise<AxiosResponse<MaterialBreakdown[]>> {
  return api({method: "get", url: `/project/${project_id}/daily/${daily_id}/material_breakdown`})
}

export async function getDailyEquipmentBreakdown(
  project_id: string,
  daily_id: string
): Promise<AxiosResponse<EquipmentBreakdown[]>> {
  return api({method: "get", url: `/project/${project_id}/daily/${daily_id}/equipment_breakdown`})
}

export async function getDailyMarkup(
  project_id: string,
  daily_id: string
): Promise<AxiosResponse<TicketMarkup>> {
  return api.get(`/project/${project_id}/daily/${daily_id}/markup`)
}

export function shareDaily(
  projectId: string,
  dailyId: string,
  message: string,
  idsToEmail: string[]
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${projectId}/daily/${dailyId}/share`,
    data: {message, idsToEmail}
  })
}

export async function attachEstimateToDaily(
  project_id: string,
  daily_id: string,
  estimate_id: string,
): Promise<AxiosResponse<{estimate_id: string}>> {
  return api({
    method: "post",
    url: `/project/${project_id}/daily/${daily_id}/attach_estimate`,
    data: {estimate_id},
  })
}

export async function removeEstimateFromDaily(
  project_id: string,
  daily_id: string,
): Promise<AxiosResponse<string>> {
  return api({
    method: "post",
    url: `/project/${project_id}/daily/${daily_id}/remove_estimate`,
  })
}

export async function getDailyLaborTimesheetReport(projectId: string): Promise<AxiosResponse<DailyLaborTimesheetReport[]>> {
  return api.get(`/project/${projectId}/daily_labor_timesheet_report`)
}


export async function getDailyInvoicePdfUrl(projectId: string, dailyId: string): Promise<AxiosResponse<{url: string}>> {
  return api.get(`/project/${projectId}/daily/${dailyId}/invoice`)
}
