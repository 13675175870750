import React from "react"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"
import { decode } from "html-entities"

import InvoiceTopAttachedFiles from "./InvoiceTopAttachedFiles"
import { File } from "../../types/file"
import { formatDate } from "../../libs/format"
import DetailsTable from "../DetailsTable"
import DetailRow from "../DetailRow"
import InvoiceTopNumber from "./InvoiceTopNumber"
import { Daily } from "../../types/daily"
import { Project } from "../../types/project"
import { createDailyTotal } from "../../util/daily"


interface DailyDetailsTopSectionProps {
  project: Project,
  files: File[] | null,
  daily: Daily,
}

export default function DailyDetailsTopSection(
  {project, files, daily}: DailyDetailsTopSectionProps
): JSX.Element {
  const { t } = useTranslation("private")

  const dailyProjectDetails =  [
    {
      title: t("view.Daily.SingleDaily.Summary.subject"),
      content: daily.subject ? decode(daily.subject) : null,
    },
    {
      title: t("view.Daily.SingleDaily.Summary.total"),
      content: createDailyTotal(daily),
    },
    {
      title: t("view.Daily.SingleDaily.Summary.projectNumber"),
      content: project.number ? `#${project.number}` : null,
    },
    {
      title: t("view.Daily.SingleDaily.Summary.project"),
      content: project.name ? decode(project.name) : null,
    },
    {
      title: t("view.Daily.SingleDaily.Summary.workDate"),
      content: daily.date_start || daily.date_end
        ? formatDate(daily.date_start, daily.date_end)
        : null,
    },
  ]

  return (
    <Grid container spacing={3}>

      <InvoiceTopNumber number={daily.number}/>
      
      {/* Daily Production and Project Details */}
      <Grid container item xs={12}>
        <DetailsTable details={dailyProjectDetails} />
        <Grid container item style={{ marginBottom: 16 }}>
          <DetailRow title={t("view.Daily.SingleDaily.Summary.description")} content={daily.description} />
          <DetailRow title={t("view.Daily.SingleDaily.Summary.notes")} content={daily.notes}/>
        </Grid>
      </Grid>

      { files != null ? <InvoiceTopAttachedFiles files={files}/> : <></> }
    </Grid>
  )
}
