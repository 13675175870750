import React from "react"
import Typography from "@mui/material/Typography"
import { GridColDef } from "@mui/x-data-grid"
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Tooltip } from "@mui/material"

import Action from "../../../../../components/Action"
import { formatDate, formatMoney } from "../../../../../libs/format"
import { ProjectUserRoleCode } from "../../../../../types/userRoles"
import { goToSingleTicket } from "../../../../../util/routes"
import FormatTableTitleLink from '../../../../../components/FormatTableTitleLink'


const cannotDeleteReason = (
  estimate_id: string|null,
  change_order_number: number|null,
  t: (s: string) => string,
): string => {
  if(estimate_id != null && change_order_number != null){
    return t("view.Tickets.TicketListColumns.DeleteTooltip.changeOrderAndEstimate")
  }
  if(estimate_id != null) return t("view.Tickets.TicketListColumns.DeleteTooltip.estimate")
  if(change_order_number != null) return t("view.Tickets.TicketListColumns.DeleteTooltip.changeOrder")
  return ""
}

export const crewColumns = (t: any, project_id: string, actionIdToInfo: {[key: string]: any}): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 110,
    },
    {
      field: "pco_number",
      headerName: t("view.Tickets.pco"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "subject",
      headerName: t("view.Tickets.subject"),
      renderCell: (params: any) => {
        return (
          <FormatTableTitleLink
            url={goToSingleTicket(project_id, params.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 250,
    },
    {
      field: "description",
      headerName: t("view.Tickets.desc"),
      editable: false,
      hide: true,
      align: "left",
      headerAlign: "left",
      width: 115,
    },
    {
      field: "action_id",
      headerName: t("view.Tickets.status"),
      renderCell: (params: any) => {
        return <Action code={actionIdToInfo[params.row.action_id].code} coAttached={!!params.row.change_order_number}/>
      },
      valueGetter: (params: any) => actionIdToInfo[params.value].name,
      editable: false,
      align: "left",
      headerAlign: "center",
      width: 180,
    },
    {
      field: "date_start",
      headerName: t("view.Tickets.work"),
      renderCell: (params: any) => params.value != null ? formatDate(params.value) : '',
      editable: false,
      hide: true,
      align: "right",
      headerAlign: "right",
      width: 150,
    },
    {
      field: "date_modified",
      type: 'dateTime',
      headerName: t("view.Tickets.modified"),
      renderCell: (params: any) => formatDate(params.value),
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 170,
    },
    {
      field: "date_created",
      headerName: t("view.Tickets.created"),
      renderCell: (params: any) => formatDate(params.value),
      editable: false,
      hide: true,
      align: "right",
      headerAlign: "right",
      width: 170,
    },
  ]
}

export const nonCrewColumns = (
  t: any,
  project_id: string,
  actionIdToInfo: {[key: string]: any},
): GridColDef[] => {
  return crewColumns(t, project_id, actionIdToInfo)
    .concat(
      [
        {
          field: "change_order_number",
          headerName: t("view.Tickets.change_order_number"),
          type: "string",
          editable: false,
          align: "center",
          headerAlign: "center",
          width: 122,
        },
        {
          field: "invoice_number",
          headerName: t("view.Tickets.inv"),
          editable: false,
          align: "left",
          headerAlign: "left",
          width: 115,
        },
        {
          field: "author",
          headerName: t("view.Tickets.inbox"),
          type: "string",
          renderCell: (params) => params.value,
          valueGetter: (params: any) => params.value,
          editable: false,
          align: "right",
          headerAlign: "right",
          width: 122,
        },
        {
          field: "total_amount",
          headerName: t("view.Tickets.total"),
          type: 'number',
          renderCell: (params) => (
            <Typography textAlign="right">
              {formatMoney(params.row.total_amount ?? 0)}
            </Typography>
          ),
          editable: false,
          align: "right",
          headerAlign: "right",
          width: 192,
        },
        {
          field: "date_closed",
          headerName: t("view.Tickets.TicketListColumns.dateClosed"),
          renderCell: (params: any) => formatDate(params.value),
          editable: false,
          hide: true,
          align: "right",
          headerAlign: "right",
          width: 170,
          type: 'dateTime'
        },
        {
          field: "first_submit_date",
          headerName: t("view.Tickets.TicketListColumns.firstSubmitDate"),
          renderCell: (params: any) => formatDate(params.value),
          editable: false,
          hide: true,
          align: "right",
          headerAlign: "right",
          width: 170,
          type: 'dateTime'
        },
        {
          field: "has_been_approved",
          headerName: t("view.Tickets.TicketListColumns.hasBeenApproved"),
          editable: false,
          hide: true,
          align: "right",
          headerAlign: "right",
          width: 50,
          type: 'boolean'
        },
        {
          field: "date_cm_cost_approved",
          headerName: t("view.Tickets.TicketListColumns.dateCmCostApproved"),
          editable: false,
          hide: true,
          align: "right",
          headerAlign: "right",
          width: 50,
          type: 'dateTime'
        },
      ]
    )
}

export const nonCrewSubColumns = (
  t: any,
  project_id: string,
  projectUserRole: ProjectUserRoleCode ,
  actionIdToInfo: {[key: string]: any},
  openConfirmCancelDeleteTicketDialog: (ticket_id: string) => void
) => {

  const columns: GridColDef[] = nonCrewColumns(t, project_id, actionIdToInfo)
  if(projectUserRole === "pm"){
    columns.push(
      {
        field: "delete",
        headerName: t("view.Tickets.TicketListColumns.delete"),
        editable: false,
        align: "center",
        headerAlign: "center",
        width: 100,
        renderCell: (params: any) => {
          return !!params.row.change_order_number || !!params.row.estimate_id ? (
            <Tooltip
              placement={'top'}
              title={cannotDeleteReason(params.row.estimate_id, params.row.change_order_number, t)}
            >
              <div>
                <IconButton disabled color="secondary">
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              color="secondary"
              onClick={() => {
                openConfirmCancelDeleteTicketDialog(params.row.id)
              }}
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      },
    )
  }

  return columns
}

export const gcColumns = (t: any, project_id: string, actionIdToInfo: {[key: string]: any}): GridColDef[] => {
  const createdByCompany: GridColDef = {
    field: "created_by_company_name",
    headerName: t("view.Tickets.company"),
    editable: false,
    align: 'left',
    headerAlign: "left",
    width: 175,
  }
  return [createdByCompany].concat(nonCrewColumns(t, project_id, actionIdToInfo))
}

export const ticketColumns = (
  companyType: string,
  projectUserRole: ProjectUserRoleCode,
  projectId: string,
  actionIdToInfo: {[key: string]: any},
  t: any,
  openConfirmCancelDeleteTicketDialog: (ticket_id: string) => void
): GridColDef[] => {
  if (companyType === 'cm') {
    return gcColumns(t, projectId, actionIdToInfo)
  }
  if (companyType === 'trade') {
    if (projectUserRole === 'crew') {
      return crewColumns(t, projectId, actionIdToInfo)
    }
    return nonCrewSubColumns(t, projectId, projectUserRole, actionIdToInfo, openConfirmCancelDeleteTicketDialog)
  }
  return []
}
