import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"
import {uploadFiles} from "./file"
import { HistoryItem } from "../types/history"
import { CompanySenderReceiver } from "../types/company"
import { TicketInvoiceSignatures } from "../types/signature"
import {Ticket, TicketLaborTimesheetReport} from '../types/ticket'


export async function getByIdTicket(project_id: string, ticket_id: string): Promise<any> {
  const {data: ticketData}  = await api.get(`/project/${project_id}/ticket/${ticket_id}`)
  return ticketData[0]
}

export async function getTicketSenderReceiver(
  project_id: string,
  ticket_id: string
): Promise<AxiosResponse<CompanySenderReceiver>> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/sender_receiver_company_info`)
}

export async function getTicketMarkup(project_id: string, ticket_id: string): Promise<any> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/markup`)
}

export async function getTicketLaborBreakdown(project_id: string, ticket_id: string): Promise<any> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/labor_breakdown`)
}

export async function getTicketMaterialBreakdown(project_id: string, ticket_id: string): Promise<any> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/material_breakdown`)
}

export async function getTicketEquipmentBreakdown(project_id: string, ticket_id: string): Promise<any> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/equipment_breakdown`)
}

export async function getTicketSignatures(project_id: string, ticket_id: string): Promise<AxiosResponse<TicketInvoiceSignatures>> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/signature`)
}

export async function getNextTicketNumber(project_id: string): Promise<AxiosResponse<{next_number: string}>> {
  return api.get(`/project/${project_id}/next-ticket-number`)
}

export async function getTicketFiles(project_id: string, ticket_id: string): Promise<any> {
  return api.get(`/project/${project_id}/ticket/${ticket_id}/file_presigned`)
}

export async function uploadTicketFiles(
  files: any[],
  project_id: string,
  ticket_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, ticket_id, 'ticket')
}

export async function createTicket(
  ticketData: any,
  projectId: string,
): Promise<any> {
  return api({
    method: "post",
    url: `/project/${projectId}/ticket`,
    data: ticketData,
  })
}

export async function editTicket(
  ticketData: any,
  ticketId: string,
  projectId: string,
): Promise<any> {
  return api({
    method: "patch",
    url: `/project/${projectId}/ticket/${ticketId}`,
    data: ticketData,
  })
}

export async function editTicketCosts(
  ticketData: any,
  ticketId: string,
  projectId: string,
): Promise<any> {
  return api({
    method: "patch",
    url: `/project/${projectId}/ticket/${ticketId}/costs`,
    data: ticketData,
  })
}

export async function getEligibleTicketsForChangeOrderForms(project_id: string): Promise<any> {
  return api.get(`/project/${project_id}/ticket_for_change_order/`)
}

export async function getTicketHistory(projectId: string, ticketId: string): Promise<AxiosResponse<HistoryItem[]>> {
  return api.get(`/project/${projectId}/ticket/${ticketId}/ticket_history`)
}

export async function getTicketsWaitingForReview(projectId: string): Promise<AxiosResponse<string[]>> {
  return api.get(`/project/${projectId}/tickets_waiting_for_review`)
}

export async function getTmReviewAvgResponseTime(projectId: string): Promise<AxiosResponse<any>> {
  return api.get(`/project/${projectId}/ticket_review_response_times`)
}

export async function getOpenItemsTickets(projectId: string): Promise<AxiosResponse<string[]>> {
  return api.get(`/project/${projectId}/open_items_tickets`)
}

export async function getTicketsApprovedByUser(projectId: string): Promise<AxiosResponse<string[]>> {
  return api.get(`/project/${projectId}/tickets_approved_by_user`)
}

export async function getTicketInvoicePdfUrl(projectId: string, ticketId: string): Promise<AxiosResponse<{url: string}>> {
  return api.get(`/project/${projectId}/ticket/${ticketId}/invoice`)
}

export async function getTicketLaborTimesheetReport(projectId: string): Promise<AxiosResponse<TicketLaborTimesheetReport[]>> {
  return api.get(`/project/${projectId}/ticket_labor_timesheet_report`)
}

export async function getBatchTicketInvoiceUrl(projectId: string, ticketIds: string[]): Promise<AxiosResponse<{url: string}>> {
  return api({
    method: "post",
    url: `/project/${projectId}/ticket/batch_invoice_url`,
    data: {ticketIds},
  })
}

export async function changeTicketIsActiveStatus(
  is_active_status: boolean,
  ticketId: string,
  projectId: string,
): Promise<any> {
  return api({
    method: "patch",
    url: `/project/${projectId}/ticket/${ticketId}/is_active_status`,
    data: {is_active: is_active_status},
  })
}

export type AttachEstimateToTicketOutput = {
  pco_number: string,
  estimate_id: string
}

export async function attachEstimateToTicket(
  projectId: string,
  ticketId: string,
  estimate_id: string,
): Promise<AxiosResponse<AttachEstimateToTicketOutput>> {
  return api({
    method: "post",
    url: `/project/${projectId}/ticket/${ticketId}/attach_estimate`,
    data: {estimate_id},
  })
}

export async function removeEstimateFromTicket(
  projectId: string,
  ticketId: string,
): Promise<AxiosResponse<string>> {
  return api({
    method: "post",
    url: `/project/${projectId}/ticket/${ticketId}/remove_estimate`,
  })
}

export async function updateTicketInvoiceInformation(
  projectId: string,
  ticketId: string,
  data: any
): Promise<AxiosResponse<Ticket>> {
  return api({
    method: 'patch',
    url: `/project/${projectId}/ticket/${ticketId}/invoice_info`,
    data,
  })
}
