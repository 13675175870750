import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { reduxStateExpired } from "../../util/expiry"

import config from "../../libs/config"
import { Ticket, TicketsStore } from "../../types/ticket"
import { ExpirationField } from "../../types/expiration"
import { TracFloState } from "../../types/state"
import {ticketActionIdCostApproveStatus} from '../../types/action'

const initialState: TicketsStore = {
  items: [],
  projectId: null,
  exp: "",
  status: "idle",
}

export const loadTickets = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadTickets",
  async (_, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        company_id: company.id,
      },
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/ticket`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, projectId: project.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const refreshTicket = createAsyncThunk<any, {ticketId: string}, {state: TracFloState}>(
  "user/refreshTicket",
  async ({ ticketId }, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "get",
      timeout: 20000,
      // url: `${company.url}${config.api.path}/project/${project.id}/ticket/${ticketId}`,
      url: `${config.api.url}/${company.id}/project/${project.id}/ticket/${ticketId}`,
    })
    if (response.status === 200 && response.data) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    addTicket: (state, action) => {
      state.items.unshift(action.payload)
    },
    deleteTicket: (state, action) => {
      state.items.splice(
        state.items.findIndex((item) => item.id === action.payload),
        1
      )
    },
    updateTicket: (state, action) => {
      if (action.payload) {
        const updatedTicket = action.payload
        updatedTicket.date_modified = (new Date()).toISOString()
        state.items = [updatedTicket, ...state.items.filter((t) => t.id !== updatedTicket.id)]
      }
    },
    updateTicketAction: (state, action: {payload: {new_action_id: number, ticket_id: string}}) => {
      const updatedTicket = state.items.find((t) => t.id === action.payload.ticket_id)
      if (updatedTicket != null) {
        updatedTicket.action_id = action.payload.new_action_id
        updatedTicket.date_modified = (new Date()).toISOString()
        if (action.payload.new_action_id === ticketActionIdCostApproveStatus) {
          updatedTicket.date_approved = (new Date()).toISOString()
          updatedTicket.has_been_approved = true
        }
        // remove the ticket
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.ticket_id),
          1
        )
        // add it back in at the front
        state.items = [updatedTicket, ...state.items]
      }
    },
    updateTicketEstimate: (
      state,
      action: {payload: {new_estimate_id: string | null, new_pco_number: string, ticket_id: string}}
    ) => {
      const updatedTicket = state.items.find((t) => t.id === action.payload.ticket_id)
      if (updatedTicket != null) {
        updatedTicket.estimate_id = action.payload.new_estimate_id
        updatedTicket.pco_number = action.payload.new_pco_number
        updatedTicket.date_modified = (new Date()).toISOString()
        // remove the ticket
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.ticket_id),
          1
        )
        // add it back in at the front
        state.items = [updatedTicket, ...state.items]
      }
    },
    resetTickets: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTickets.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadTickets.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadTickets.fulfilled, (state, action: {payload: {data: Ticket[], projectId: string}}) => {
        if (action.payload && action.payload.data) {
          const tickets = action.payload.data.filter((row) => row.id)
          state.items = tickets
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.projectId = action.payload.projectId
        }
        state.status = "fulfilled"
      })
      .addCase(refreshTicket.fulfilled, (state, action) => {
        if (action.payload && action.payload.id) {
          const newTicket = action.payload
          let updated = false
          if (state.items.length) {
            state.items = state.items.map((item) => {
              if (item && item.id === newTicket.id) {
                updated = true
                return newTicket
              } else {
                return item
              }
              // return item && item.id === newTicket.id ? newTicket : item
            })
          }
          if (!updated) {
            state.items.unshift(newTicket)
          }
        }
      })
  },
})

export const { addTicket, deleteTicket, updateTicket, resetTickets, updateTicketAction, updateTicketEstimate } = ticketsSlice.actions

export const listTickets = (state: TracFloState) => state.tickets.items

export const getTicket = (ticketId: string) => (state: TracFloState) =>
  state.tickets.items.find((item) => item && item.id === ticketId)

export const reloadTicketsIfInvalid = (
  projectId: string,
  ticketsProjectId: string | null,
  ticketExpiry: ExpirationField,
  dispatch: any,
) => {
  if (projectId !== ticketsProjectId || reduxStateExpired(ticketExpiry)) {
    dispatch(loadTickets())
  }
}

export default ticketsSlice.reducer
