import React, { useEffect } from "react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import AddIcon from '@mui/icons-material/Add'
import {GridColumnVisibilityModel} from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'

//@ts-ignore
import Container from "../../../../../components/Container"
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { listEstimates, loadEstimates, reloadEstimatesIfInvalid } from "../../../../../store/features/estimateSlice"
import RefreshButton from "../../../../../components/refreshButton"
import { useFilter } from "../../../../../hook/tableFilter"
import { EstimateListColumns } from "./EstimateList.columns"
import FabLink from '../../../../../components/FabLink'
import {goToCreateEstimate} from '../../../../../util/routes'
import {useTableColumns} from '../../../../../hook/tableColumns'


const tableName = 'EstimatesList'

export default function EstimateList() {
  const {t} = useTranslation('private')
  const dispatch = useDispatch()
  const { projectId } = useParams<{projectId: string}>()
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  const isSuperadmin = useTracFloSelector((state) => state.user?.is_admin)
  const estimates = useTracFloSelector(listEstimates)
  const estimatesProjectId = useTracFloSelector((state) => state.estimates.projectId)
  const estimatesExpiry = useTracFloSelector((state) => state.estimates.exp)
  const actionIdObjMapping = useTracFloSelector((state) => state?.estimateAction?.idObjectMapping)
  const [filter, setFilter] = useFilter(tableName)

  const columns = EstimateListColumns(
    projectId,
    t,
    actionIdObjMapping,
    company.company_type
  )

  const [globalTableColumns, setGlobalTableColumns] = useTableColumns(tableName, columns)

  const showAddEstimateButton = company.company_type === 'trade' && !isSuperadmin

  useEffect(() => {
    if (projectId === project.id) {
      reloadEstimatesIfInvalid(projectId, estimatesProjectId, estimatesExpiry, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project.id])

  const refreshEstimateList = () => {
    return <RefreshButton reload={loadEstimates} />
  }

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Estimates.EstimateList.header")}</Typography>
          </Grid>

          {showAddEstimateButton ? (
            <Grid container item alignItems="center" justifyContent={"flex-end"} md={6}>
              <FabLink
                url={goToCreateEstimate(projectId)}
                buttonText={t("view.Estimates.EstimateList.addButton")}
                LeadingIconComponent={<AddIcon/>}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>

      <Container fullWidth removeTop>
        <DataTable
          checkboxSelection={false}
          columns={columns}
          rows={estimates}
          filterModel={filter}
          onFilterModelChange={setFilter}
          additionalToolbars={
            [refreshEstimateList]
          }
          columnVisibilityModel={globalTableColumns}
          onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
            setGlobalTableColumns(newModel)
          }}
        />
      </Container>
    </>
  )
}
