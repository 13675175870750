import { useEffect, useMemo, useState } from 'react'
import Paper from "@mui/material/Paper"
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'

import {Project} from '../../../../../../types/project'
import { File } from '../../../../../../types/file'
import { Daily } from '../../../../../../types/daily'
import { getDailyFiles } from '../../../../../../api/daily'
import DailyDetailsTopSection from '../../../../../../components/Invoice/DailyDetailsTopSection'
import DailyBreakdownMarkupCostDetail from '../DailyBreakdownMarkupCostDetail'
import { convertDailyToTicketType, createDailyTotal } from '../../../../../../util/daily'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 1080,
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    "@media print": {
      maxWidth: 9999,
      padding: 0,
    },
    totals: {
      paddingTop: 20,
    },
  },
}))

interface DailyInvoiceProps {
  daily: Daily,
  project: Project,
}

export default function DailyInvoice(
  {daily, project}: DailyInvoiceProps
): JSX.Element {
  const classes = useStyles()
  const [dailyFiles, setDailyFiles] = useState<File[] | null>(null)
  const dailyTicketType = useMemo(() => {
    return convertDailyToTicketType(daily)
  }, [daily])

  useEffect(() => {
    if (!!daily.id) {
      getDailyFiles(project.id, daily.id).then(
        (res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            setDailyFiles(res.data)
          }
        }
      )
    }
  }, [daily.id, project.id])

  return daily.id && project.id
    ? <Paper className={classes.root} elevation={2}>
      <DailyDetailsTopSection project={project} files={dailyFiles} daily={daily} />

      <DailyBreakdownMarkupCostDetail 
        dailyId={daily.id} 
        projectId={project.id}
        dailyTicketType={dailyTicketType}
        dailyTotal={createDailyTotal(daily) ?? ''}
        lumpSumTotal={daily.lump_sum_total}
      />
    </Paper>
    : <></>
}
