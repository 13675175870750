import { StoreBase } from "./storeBase"
import {LoadingStatus} from "./status"
import { ExpirationField } from "./expiration"
import {LaborTimesheetReportRateType} from './ticket'
import {CsvHeader} from '../util/csv/createAndDownloadCsv'


export interface Daily {
  id: string,
  includes_costs: boolean,
  is_active: boolean,
  date_end?: string,
  date_start: string,
  description: string,
  is_lump_sum: boolean,
  lump_sum_total?: string | number,
  number: string,
  subject: string,
  total_amount: string | number,
  notes?: string,
  estimate_id: string | null,
  date_modified: string,
}

export type DailyStore = {items: Daily[], projectId: string | null} & StoreBase<LoadingStatus, ExpirationField>

export type DailyLaborTimesheetReport = {
  project_number?: string,
  daily_report_number: string,
  labor_type: string,
  labor_date: string,
  hours_per_person: number,
  crew_size: number,
  rate_type: LaborTimesheetReportRateType,
  rate: number,
}

export const DailyLaborTimesheetReportCsvHeaders: CsvHeader[] = [
  {
    value: 'project_number',
    type: 'string'
  },
  {
    value: 'daily_report_number',
    type: 'string'
  },
  {
    value: 'labor_type',
    type: 'string'
  },
  {
    value: 'labor_date',
    type: 'date'
  },
  {
    value: 'hours_per_person',
    type: 'number'
  },
  {
    value: 'crew_size',
    type: 'number'
  },
  {
    value: 'rate_type',
    type: 'string'
  },
  {
    value: 'rate',
    type: 'number'
  },
]
