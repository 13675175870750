import { resetUser, setUserFromToken } from "./features/userSlice"
import { reset as resetAction } from "./features/actionSlice"
import { resetProject } from "./features/projectSlice"
import { resetTickets } from "./features/ticketsSlice"
import { resetEquipment } from "./features/equipmentSlice"
import { resetLabor } from "./features/laborSlice"
import { resetMaterial } from "./features/materialSlice"
import { resetChangeOrders } from "./features/changeOrdersSlice"
import { resetBulletins } from "./features/bulletinSlice"
import { resetEstimates } from "./features/estimateSlice"
import { resetCompany } from "./features/companySlice"
import { reset as resetCoAction } from "./features/changeOrderActionSlice"
import { reset as resetBulletinAction } from "./features/bulletinActionSlice"
import { reset as resetEstimateAction } from "./features/estimateActionSlice"
import {reset as resetTableFilters} from "./features/tableFilterSlice"
import {reset as resetTableColumns} from "./features/tableColumnsSlice"
import {reset as resetIntegration} from "./features/integrationSlice"
import { resetDaily } from "./features/dailySlice"
import {reset as resetUserTesting} from "./features/userTestingSlice"


import { storeReducers } from "./store"

const storeResetActions: any = {
  estimateAction: resetEstimateAction,
  bulletinAction: resetBulletinAction,
  changeOrderAction: resetCoAction,
  action: resetAction,
  user: resetUser,
  project: resetProject,
  daily: resetDaily,
  tickets: resetTickets,
  equipment: resetEquipment,
  labor: resetLabor,
  material: resetMaterial,
  changeOrders: resetChangeOrders,
  bulletins: resetBulletins,
  estimates: resetEstimates,
  company: resetCompany,
  tableFilters: resetTableFilters,
  tableColumns: resetTableColumns,
  integration: resetIntegration,
  userTesting: resetUserTesting,
}

const storeResetProjectActions: any = {
  project: resetProject,
  daily: resetDaily,
  tickets: resetTickets,
  labor: resetLabor,
  changeOrders: resetChangeOrders,
  tableFilters: resetTableFilters,
  bulletins: resetBulletins,
  estimates: resetEstimates,
}

// we need to check that all store items are represented here
function compareResetToStore() {
  // first compare store to reset actions
  for (let reducerKey of Object.keys(storeReducers)) {
    if (!storeResetActions[reducerKey]) {
      const message = `NEED TO ADD RESET ACTION TO resetStoreService: ${reducerKey}`
      console.log(message)
      throw new Error(message)
    }
  }
  // then compare reset actions to store
  for (let resetKey of Object.keys(storeResetActions)) {
    // @ts-ignore
    if (!storeReducers[resetKey]) {
      const message = `NEED TO ADD REDUCER TO store: ${resetKey}`
      console.log(message)
      throw new Error(message)
    }
  }
}

compareResetToStore()

export const resetStore = (dispatch: any) => {
  for (let resetAction of Object.keys(storeResetActions)) {
    dispatch(storeResetActions[resetAction]())
  }
}

export const resetStoreForProject = (dispatch: any) => {
  for (let resetAction of Object.keys(storeResetProjectActions)) {
    dispatch(storeResetProjectActions[resetAction]())
  }
}

export const resetToImpersonateUser = (dispatch: any, userToken: string) => {
  resetStore(dispatch)
  dispatch(setUserFromToken({token: userToken}))
}
