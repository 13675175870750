import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import {useParams} from "react-router-dom"
import { Dialog, useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import { useDispatch } from "react-redux"
import { useSnackbar } from "notistack"
import {GridColumnVisibilityModel} from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'

import {ChangeOrdersListColumns} from "./ChangeOrdersList.columns"
//@ts-ignore
import Container from "../../../../../components/Container"
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import FeatureAccessButton from "../../../../../components/FeatureAccessButton"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import {
  deleteChangeOrder as deleteChangeOrderGlobally,
  loadChangeOrders,
  reloadChangeOrdersIfInvalid
} from "../../../../../store/features/changeOrdersSlice"
import RefreshButton from "../../../../../components/refreshButton"
import { useFilter } from "../../../../../hook/tableFilter"
import { changeCoIsActiveStatus } from "../../../../../api/changeOrder"
import ConfirmOrCancelPrompt from "../../../../../components/ConfirmOrCancelPrompt"
import {useTableColumns} from '../../../../../hook/tableColumns'


const tableName = 'ChangeOrdersList'

export default function ChangeOrder() {
  const dispatch = useDispatch()
  const { projectId } = useParams<{projectId: string}>()
  const { enqueueSnackbar } = useSnackbar()
  const project = useTracFloSelector((state) => state.project)
  const company = useTracFloSelector((state) => state.company)
  const isSuperadmin = useTracFloSelector((state) => state.user?.is_admin)
  const changeOrders: any = useTracFloSelector((state) => state.changeOrders.items)
  const changeOrdersProjectId = useTracFloSelector((state) => state.changeOrders.projectId)
  const changeOrderExpiry = useTracFloSelector((state) => state.changeOrders.exp)
  const coActionIdObjMapping = useTracFloSelector((state) => state?.changeOrderAction?.idObjectMapping)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")
  const [filter, setFilter] = useFilter(tableName)
  const [deleteChangeOrderId, setDeleteChangeOrderId] = useState<string | null>(null)
  const [openDeleteChangeOrderDialog, setOpenDeleteChangeOrderDialog] = useState<boolean>(false)

  const openConfirmCancelDeleteChangeOrderDialog = async (changeOrderId: string): Promise<void> => {
    setDeleteChangeOrderId(changeOrderId)
    setOpenDeleteChangeOrderDialog(true)
  }

  const closeConfirmCancelDeleteChangeOrderDialog = () => {
    setOpenDeleteChangeOrderDialog(false)
  }

  // only trades. superadmins arent allowed to add
  const showAddChangeOrderButton = company.company_type === 'trade' && !isSuperadmin

  const canAddChangeOrders = project
    && project.project_license_is_active
    && project.change_order_is_active

  // If you can add change orders you can delete them
  const canDeleteChangeOrders = showAddChangeOrderButton && canAddChangeOrders

  const columns = ChangeOrdersListColumns(
    projectId,
    company.company_type,
    coActionIdObjMapping,
    t,
    canDeleteChangeOrders,
    openConfirmCancelDeleteChangeOrderDialog
  )

  const [globalTableColumns, setGlobalTableColumns] = useTableColumns(tableName, columns)

  useEffect(() => {
    if (projectId === project.id) {
      reloadChangeOrdersIfInvalid(projectId, changeOrdersProjectId, changeOrderExpiry, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project.id])

  const refreshChangeOrderList = () => {
    return <RefreshButton reload={loadChangeOrders} />
  }

  const deleteChangeOrder = async () => {
    closeConfirmCancelDeleteChangeOrderDialog()
    if(deleteChangeOrderId != null){
      try {
        const { data } = await changeCoIsActiveStatus(
          false,
          projectId,
          deleteChangeOrderId
        )
        if (!!data.id) {
          dispatch(deleteChangeOrderGlobally(data.id)) // Remove the change order once we have a successful call.
          enqueueSnackbar(t("view.ChangeOrder.deleteSuccess"), {
            variant: "success",
          })
        } else {
          enqueueSnackbar(t("view.ChangeOrder.deleteFail"), {
            variant: "error",
          })
        }
      } catch(_) {
        enqueueSnackbar(t("view.ChangeOrder.deleteFail"), {
          variant: "error",
        })
      }
    }
  }

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.ChangeOrder.changeOrders")}</Typography>
          </Grid>
          {showAddChangeOrderButton
            ? <Grid
                container
                item
                alignItems="center"
                justifyContent={isMobile ? "flex-start" : "flex-end"}
                md={6}
              >
                <FeatureAccessButton
                  canAccessFeature={canAddChangeOrders}
                  supportDialogFeatureDesc={t("view.ChangeOrder.supportDialogFeatureCODesc")}
                  supportDialogTitle={t("view.ChangeOrder.add")}
                  linkProps={{
                    url: `/0/project/${projectId}/changeorders/add`,
                    buttonText: t("view.ChangeOrder.button"),
                    LeadingIconComponent: <AddIcon/>
                  }}
                />
              </Grid>
            : <></>
          }
        </Grid>
      </Container>

      <Container fullWidth removeTop>
        <DataTable
          checkboxSelection={false}
          columns={columns}
          rows={changeOrders.filter((item: any) => item && item.id)}
          filterModel={filter}
          onFilterModelChange={setFilter}
          additionalToolbars={
            [refreshChangeOrderList]
          }
          columnVisibilityModel={globalTableColumns}
          onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
            setGlobalTableColumns(newModel)
          }}
        />
      </Container>

      {/* Confirm Cancel Delete Change Order Dialog */}
      <Dialog
        open={openDeleteChangeOrderDialog}
        onClose={closeConfirmCancelDeleteChangeOrderDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt
          submitForm={deleteChangeOrder}
          closeDialog={closeConfirmCancelDeleteChangeOrderDialog}
          dialogPrompt={t("view.ChangeOrder.deleteChangeOrderPrompt")}
        />
      </Dialog>
    </>
  )
}
