import React from "react"
import Typography from "@mui/material/Typography"

// @ts-ignore
import FormatRowId from "../../../../../../components/FormatRowId"
import FormatTableTitle from "../../../../../../components/FormatTableTitle"
// @ts-ignore
import { formatDate, formatMoney } from "../../../../../../libs/format"
// @ts-ignore
import {getRateTypeName} from "../../../../../../libs/rateTypes"
import {BreakdownTableColumn} from "../../../../../../components/BreakdownTable.columns"

interface LaborBreakdownColumnsProps {
  addRates: boolean,
  t: (s: string) => string,
}

export default function LaborBreakdownColumns({addRates, t}: LaborBreakdownColumnsProps): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      width: 50,
      sortable: false,
      style: { paddingLeft: 0 },
      renderCell: (params: any) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: t("view.ChangeOrder.Labor.type"),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            <FormatTableTitle>{params.value}</FormatTableTitle>
            <Typography style={{ color: "#757575", fontSize: 12 }}>
              {formatDate(params.date)}
            </Typography>
          </>
        )
      },
    },
    {
      align: "center",
      headerAlign: "center",
      field: "cost_code",
      headerName: t("view.ChangeOrder.Labor.cost_code"),
      hide: !addRates,
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "rate_type",
      headerName: t("view.ChangeOrder.Labor.rate"),
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: false,
      renderCell: (params: any) => getRateTypeName(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "crew_size",
      headerName: t("view.ChangeOrder.Labor.crew_size"),
      width: 70,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "hours_per_person",
      headerName: t("view.ChangeOrder.Labor.hour_person"),
      width: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_hours",
      headerName: t("view.ChangeOrder.Labor.total_hrs"),
      width: 70,
      editable: false,
      sortable: false,
      renderCell: (params: any) => params.hours_per_person * params.crew_size,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate",
      headerName: t("view.ChangeOrder.Labor.hour_rate"),
      hide: !addRates,
      flex: 1,
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_cost",
      headerName: t("view.ChangeOrder.Labor.total_cost"),
      hide: !addRates,
      flex: 1,
      minWidth: 90,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.rate * params.hours_per_person * params.crew_size),
    },
  ]
}
