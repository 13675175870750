import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

import changeOrdersReducer from "./features/changeOrdersSlice"
import bulletinsReducer from "./features/bulletinSlice"
import estimatesReducer from "./features/estimateSlice"
import companyReducer from "./features/companySlice"
import equipmentReducer from "./features/equipmentSlice"
import laborReducer from "./features/laborSlice"
import materialReducer from "./features/materialSlice"
import projectReducer from "./features/projectSlice"
import ticketsReducer from "./features/ticketsSlice"
import userReducer from "./features/userSlice"
import actionReducer from "./features/actionSlice"
import changeOrderActionReducer from "./features/changeOrderActionSlice"
import bulletinActionReducer from "./features/bulletinActionSlice"
import estimateActionReducer from "./features/estimateActionSlice"
import tableFiltersReducer from "./features/tableFilterSlice"
import tableColumnsReducer from "./features/tableColumnsSlice"
import integrationReducer from "./features/integrationSlice"
import dailyReducer from "./features/dailySlice"
import userTestingReducer from "./features/userTestingSlice"

const persistChangeOrders = {
  key: "changeOrders",
  storage,
}
const persistBulletins = {
  key: "bulletins",
  storage,
}
const persistEstimates = {
  key: "estimates",
  storage,
}
const persistCompany = {
  key: "company",
  storage,
}
const persistEquipment = {
  key: "equipment",
  storage,
}
const persistLabor = {
  key: "labor",
  storage,
}
const persistMaterial = {
  key: "material",
  storage,
}
const persistUser = {
  key: "user",
  storage,
}
const persistProject = {
  key: "project",
  storage,
}
const persistDaily = {
  key: "daily",
  storage,
}
const persistTickets = {
  key: "tickets",
  storage,
}
const persistActions = {
  key: "action",
  storage,
}
const persistChangeOrderActions = {
  key: "changeOrderAction",
  storage,
}
const persistBulletinActions = {
  key: "bulletinAction",
  storage,
}
const persistEstimateActions = {
  key: "estimateAction",
  storage,
}
const persistTableFilters = {
  key: "tableFilters",
  storage
}
const persistTableColumns = {
  key: "tableColumns",
  storage
}
const persistIntegration = {
  key: "integration",
  storage
}
const persistUserTesting = {
  key: 'userTesting',
  storage,
}

export const storeReducers = {
  changeOrders: persistReducer(persistChangeOrders, changeOrdersReducer),
  bulletins: persistReducer(persistBulletins, bulletinsReducer),
  estimates: persistReducer(persistEstimates, estimatesReducer),
  company: persistReducer(persistCompany, companyReducer),
  equipment: persistReducer(persistEquipment, equipmentReducer),
  labor: persistReducer(persistLabor, laborReducer),
  material: persistReducer(persistMaterial, materialReducer),
  project: persistReducer(persistProject, projectReducer),
  daily: persistReducer(persistDaily, dailyReducer),
  tickets: persistReducer(persistTickets, ticketsReducer),
  user: persistReducer(persistUser, userReducer),
  action: persistReducer(persistActions, actionReducer),
  changeOrderAction: persistReducer(persistChangeOrderActions, changeOrderActionReducer),
  bulletinAction: persistReducer(persistBulletinActions, bulletinActionReducer),
  estimateAction: persistReducer(persistEstimateActions, estimateActionReducer),
  tableFilters: persistReducer(persistTableFilters, tableFiltersReducer),
  tableColumns: persistReducer(persistTableColumns, tableColumnsReducer),
  integration: persistReducer(persistIntegration, integrationReducer),
  userTesting: persistReducer(persistUserTesting, userTestingReducer),
}

const reducers = combineReducers(storeReducers)

const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
})

export default store
