import {ListOption} from "../../../../../../../../types/form"

export type PushToChangeEventType = 'new' | 'existing'
export const pushToChangeEventTypes = ['new', 'existing']

export interface ChangeEventDetailsFormOutput {
  existingChangeEvent: ListOption<string> | null, // null ID means they are creating a new change event
}

export function formIsValid(pushToCeType: PushToChangeEventType, selectedCe: ListOption<string> | null): boolean {
  return pushToCeType === 'new' || (pushToCeType === 'existing' && selectedCe != null)
}
