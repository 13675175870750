import React, {useState, useEffect} from "react"
import {useHistory, useParams} from "react-router-dom"
import { useTranslation } from "react-i18next"
import { decode } from "html-entities"
import parse from "html-react-parser"
import { Grid } from "@mui/material"
import { Typography } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"

import {AddGcToSubProject} from "./ProjectSettings/AddGcToSubProject"
import LaborView from "./ProjectSettings/Labor"
import UsersView from "./ProjectSettings/Users"
import Subcontractors from "./Subcontractor"
import {getGcsOnProject} from "../../../../api/company"
// @ts-ignore
import Container from "../../../../components/Container"
import DetailsTable from "../../../../components/DetailsTable"
// @ts-ignore
import Dialog from "../../../../components/Dialog"
import Fab from "../../../../components/Fab"
// @ts-ignore
import Tabs from "../../../../components/Tabs"
import UpdateProjectForm from "../../../../forms/UpdateProject"
import { formatDate } from "../../../../libs/format"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"
import { goHome } from "../../../../util/routes"
import {AddGcToSubProjectByEmail} from './ProjectSettings/AddGcToSubProjectByEmail'


export default function Settings() {
  const { projectId } = useParams<{ projectId: string }>()
  const project = useTracFloSelector((state) => state.project)
  const company = useTracFloSelector((state) => state.company)
  const user = useTracFloSelector((state) => state.user)
  const { t } = useTranslation("private")
  const history = useHistory()
  const [dialog, setDialog] = useState<string | null>(null)
  const [noGcOnProject, setNoGcOnProject] = useState<boolean>(false)
  const userCanAddGcToProject = user.is_admin || (company.company_type === 'trade' && project.project_user_role === 'pm')
  const userCanEditProject = project?.project_user_role === "pm" && project.license_owner === company.id

  useEffect(() => {
    // Superusers will see another tab if there is no GC on the project
    if (userCanAddGcToProject && project.id) {
      getGcsOnProject(project.id).then((res) => {
        if (res.data?.length === 0) {
          setNoGcOnProject(true)
        }
      })
    }
  }, [user.is_admin, project.id, userCanAddGcToProject])

  const gcAddedToProject = () => {
    setNoGcOnProject(false)
    history.push(goHome)
  }

  const summary = [
    {
      title: t("view.Settings.number"),
      content: project?.number ? project.number : "N/A",
    },
    {
      title: t("view.Settings.startDate"),
      content: project?.date_start ? formatDate(project.date_start) : "N/A",
    },
    {
      title: t("view.Settings.address"),
      content: project?.address ? parse(project.address) : "N/A",
    },
  ]

  const clientTabs = [
    {
      navigationText: t("view.Settings.users"),
      content: <UsersView />,
    },
    {
      navigationText: t("view.Settings.labor"),
      content: <LaborView />,
    },
  ]

  if (noGcOnProject && userCanAddGcToProject) {
    clientTabs.push({
      navigationText: t("view.Settings.addGc"),
      // only superadmins should add users using the dropdown
      content: user.is_admin
        ? <AddGcToSubProject projectId={project.id} gcAddedToProject={gcAddedToProject}/>
        : <AddGcToSubProjectByEmail projectId={project.id} gcAddedToProject={gcAddedToProject}/>,
    })
  }

  if (company.company_type === "cm" && project.project_user_role === "pm") {
    clientTabs.push({
      navigationText: t("view.Settings.subcontractors"),
      content: <Subcontractors />,
    })
  }

  return project.id === projectId ? (
    <>
      <Container>
        <Grid container alignItems="center">
          <Grid container justifyContent="flex-start" item xs={6} md={8}>
            <Typography variant="h1">{decode(project?.name)}</Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={6} md={4}>
            {userCanEditProject ? (
              <Fab
                // @ts-ignore
                edge="end"
                variant="extended" onClick={() => setDialog("settingsform")}
              >
                <EditIcon />
                Edit
              </Fab>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            <DetailsTable details={summary} />
          </Grid>
        </Grid>
      </Container>

      <Tabs tabs={clientTabs}></Tabs>

      <Dialog
        hideActions={true}
        open={dialog === "settingsform"}
        onClose={() => setDialog(null)}
        title="Project Settings"
      >
        <UpdateProjectForm onCancel={() => setDialog(null)} />
      </Dialog>
    </>
  ) : (
    <></>
  )
}
