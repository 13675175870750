import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Theme } from "@mui/material"

import { BulletinActionCode } from "../types/action"


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[900],
    border: "none",
    borderRadius: 5,
    color: "white",
    fontWeight: 900,
    fontSize: 13,
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  draft: {
    background: theme.palette.grey[600],
  },
  review: {
    background: "#f2c746",
  },
  estimates_approved: {
    background: "#45bf55",
  },
  owner_approved: {
    background: "#143366",
    opacity: "0.4",
  },
  not_needed: {
    background: "#FFFFFF",
    border: "solid 2px",
    borderColor: "#516487",
    color: "#687997",
  },
}))

interface BulletinActionProps {
  code: BulletinActionCode
}

export default function BulletinAction({code}: BulletinActionProps) {
  const { t } = useTranslation("private")
  const classes = useStyles()
  const className = code

  return (
    <Box className={`${classes.root} ${classes[className]}`}>
      <Typography>{t(`component.BulletinAction.${code}`)}</Typography>
    </Box>
  )
}
