import { StoreBase } from "./storeBase"
import {LoadingStatus} from "./status"

export type ActionCode = 'draft'
  | 'tm_submitted' | 'tm_approve' | 'tm_reject' | 'tm_revise'
  | 'cost_submitted' | 'cost_reject' | 'cost_revise'
  | 'cost_reviewed' | 'cost_owner' | 'cost_approve'

export const ticketActionIdCostApproveStatus = 9

export type ChangeOrderActionCode = 'draft' | 'approve' | 'submitted' | 'revise'

export const changeOrderActionIdRejectStatus = 5

export type BulletinActionCode = 'draft' | 'review' | 'estimates_approved' | 'owner_approved'

export type EstimateActionCode = 'pending' | 'draft' | 'submitted' | 'revise' | 'gc_approved' | 'tm_authorized'

export interface ActionInfo<T = ActionCode | ChangeOrderActionCode | BulletinActionCode | EstimateActionCode> {
  code: T,
  id: number,
  name: string,
}

export type ActionsStore<T> = {
  items: ActionInfo<T>[],
  idObjectMapping: {[key: number]: ActionInfo<T>}
} & StoreBase<LoadingStatus>
