import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { reduxStateExpired } from "../../util/expiry"
import { TracFloState } from "../../types/state"
import { ExpirationField } from "../../types/expiration"
import {apiHeaders} from '../../api/util'
import { Daily, DailyStore } from "../../types/daily"

const initialState: DailyStore = {
  items: [],
  projectId: null,
  exp: "",
  status: "idle",
}

export const loadDaily = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadDaily",
  async (_, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: apiHeaders(company.id, token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/daily`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, projectId: project.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const dailySlice = createSlice({
  name: "daily",
  initialState,
  reducers: {
    addDaily: (state, action: {payload: Daily}) => {
      state.items.unshift(action.payload)
    },
    resetDaily: () => initialState,
    updateDaily: (state, action) => {
      if (action.payload) {
        const updatedDaily = action.payload
        updatedDaily.date_modified = (new Date()).toISOString()
        state.items = [updatedDaily, ...state.items.filter((t) => t.id !== updatedDaily.id)]
      }
    },
    updateDailyEstimate: (
      state,
      action: {payload: {new_estimate_id: string | null, daily_id: string}}
    ) => {
      const updatedDaily = state.items.find((d) => d.id === action.payload.daily_id)
      if (updatedDaily != null) {
        updatedDaily.estimate_id = action.payload.new_estimate_id
        updatedDaily.date_modified = (new Date()).toISOString()
        // remove the daily
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.daily_id),
          1
        )
        // add it back in at the front
        state.items = [updatedDaily, ...state.items]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDaily.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadDaily.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadDaily.fulfilled, (state, action: {payload: {data: Daily[], projectId: string}}) => {
        if (action.payload && action.payload.data) {
          state.items =  action.payload.data.filter((row) => row.id).map((d) => {
            return d
          })
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.projectId = action.payload.projectId
        }
        state.status = "fulfilled"
      })
  },
})

export const { resetDaily, addDaily, updateDaily, updateDailyEstimate } = dailySlice.actions

export const listDaily = (state: TracFloState) => state.daily.items

export const reloadDailyIfInvalid = (
  projectId: string,
  dailyProjectId: string | null,
  dailyExpiry: ExpirationField,
  dispatch: any,
) => {
  if (projectId !== dailyProjectId || reduxStateExpired(dailyExpiry)) {
    dispatch(loadDaily())
  }
}

export default dailySlice.reducer
