import React from "react"
import {GridColDef} from "@mui/x-data-grid"
import { Typography } from "@mui/material"

import { formatDate, formatMoney } from '../../../../../libs/format'
import { ProjectUserRoleCode } from "../../../../../types/userRoles"
import {goToSingleDaily} from '../../../../../util/routes'
import FormatTableTitleLink from '../../../../../components/FormatTableTitleLink'


export const DailyListColumns = (
  projectUserRole: ProjectUserRoleCode,
  t: any,
  projectId: string,
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: 'string',
      align: "left",
      headerAlign: "left",
      width: 100,
      editable: false,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Daily.DailyList.DailyListColumns.subject"),
      width: 250,
      editable: false,
      renderCell: (params: any) => (
        <FormatTableTitleLink
          url={goToSingleDaily(projectId, params.row.id)}
          text={params.value}
          openNewWindow={false}
        />
      )
    },
    {
      field: "description",
      headerName: t("view.Daily.DailyList.DailyListColumns.description"),
      editable: false,
      hide: true,
      align: "left",
      headerAlign: "left",
      width: 115,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_start",
      headerName: t("view.Daily.DailyList.DailyListColumns.date_start"),
      width: 170,
      editable: false,
      renderCell: (params: any) => {
        return formatDate(params.value)
      },
      type: 'dateTime'
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_modified",
      headerName: t("view.Daily.DailyList.DailyListColumns.modified"),
      width: 170,
      editable: false,
      renderCell: (params: any) => {
        return formatDate(params.value)
      },
      type: 'dateTime'
    },
  ]

  if(projectUserRole !== 'crew'){
    columns.push(
      {
        field: "total_amount",
        headerName: t("view.Daily.DailyList.DailyListColumns.total"),
        type: 'number',
        renderCell: (params) => (
          <Typography textAlign="right">
            {formatMoney(params.row.total_amount ?? 0)}
          </Typography>
        ),
        editable: false,
        align: "right",
        headerAlign: "right",
        width: 192,
      },
      {
        field: "author",
        headerName: t("view.Daily.DailyList.DailyListColumns.inbox"),
        type: "string",
        renderCell: (params: any) => params.value,
        editable: false,
        align: "right",
        headerAlign: "right",
        width: 122,
      },
    )
  }

  return columns
}
