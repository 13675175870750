import React, { useRef } from "react"
import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import { useTheme } from "@mui/material"

//@ts-ignore
import Container from "../../../../../components/Container"
import { getByIdTicket } from "../../../../../api/ticket"
import TicketInvoice from "./TicketInvoice"
import TicketReview from "./TicketReview"
import TicketHistory from "./History"

export default function Ticket() {
  const [ticket, setTicket] = React.useState(null)
  const { ticketId, projectId } = useParams<{ ticketId: string, projectId: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const project = useSelector((state: any) => state.project)
  const historyRef = useRef<any>()

  React.useEffect(() => {
    if (projectId && ticketId) {
      getByIdTicket(projectId, ticketId)
        .then((ticketData) => {
          setTicket(ticketData)
        })
        .catch(() => {
          enqueueSnackbar("There was a problem getting the ticket information.", {
            variant: "error",
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, ticketId])

  return !!ticket && project.id === projectId ? (
    <div>
      <TicketReview
        ticket={ticket}
        historyRef={historyRef}
        projectId={project.id}
        projectUserRole={project.project_user_role}
        setTicket={setTicket}
      />

      <Container fullWidth style={{ background: theme.palette.grey[200] }}>
        <TicketInvoice ticket={ticket} project={project} />
      </Container>

      <div ref={historyRef}>
        <Container>
          <TicketHistory ticket_id={ticketId} project_id={projectId} />
        </Container>
      </div>
    </div>
  ) : (
    <></>
  )
}
