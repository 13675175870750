import React from "react"
import parse from "html-react-parser"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import EditIcon from "@mui/icons-material/Edit"

import EditCompanyLogoButton from "./Company/EditCompanyLogoButton"
//@ts-ignore
import MaterialEquipmentView from "./MaterialsEquipment"
//@ts-ignore
import UsersView from "./Users"
//@ts-ignore
import Container from "../../../../components/Container"
import DetailsTable from "../../../../components/DetailsTable"
//@ts-ignore
import Dialog from "../../../../components/Dialog"
//@ts-ignore
import Fab from "../../../../components/Fab"
import Logo from "../../../../components/LogoPortable"
//@ts-ignore
import Tabs from "../../../../components/Tabs"
//@ts-ignore
import EditCompany from "../../../../forms/EditCompany"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"

export default function Settings() {
  const { t } = useTranslation("private")
  const [dialog, setDialog] = React.useState<string | null>(null)

  const company = useTracFloSelector((state) => state.company)

  const summary = [
    {
      title: t("view.Settings.phone"),
      content: company.phone ? company.phone : "N/A",
    },
    {
      title: t("view.Settings.address"),
      content: company.address ? company.address : "N/A",
    },
  ]

  const tabs = [
    {
      navigationText: t("view.Settings.users"),
      content: <UsersView />,
    },
  ]

  if (company.company_type === 'trade') {
    // Trade can see and use materials and equipment
    tabs.push(
      {
        navigationText: t("view.Settings.material_equip"),
        content: <MaterialEquipmentView />,
      },
    )
  }

  return (
    <>
      <Container>
        <Grid container alignItems="center">
          <Grid container justifyContent="flex-start" item xs={6} md={8}>
            <Typography variant="h1">{t("view.Settings.account_settings")}</Typography>
          </Grid>
          {company.company_user_role === "admin" ? (
            <Grid container justifyContent="flex-end" item xs={6} md={4}>
              <Fab
                //@ts-ignore
                edge="end"
                variant="extended"
                onClick={() => setDialog("settingsform")}>
                  <EditIcon />
                    {t("view.Tickets.edit")}
              </Fab>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">{parse(company.name)}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailsTable details={summary} />
          </Grid>
          <Grid container justifyContent="flex-end" item xs={12} md={6}>
            {company.company_user_role === "admin" ? (
              <EditCompanyLogoButton company={company}/>
            ) : (
              <></>
            )}
            {company.logo_url ? (
              <>
                <Logo companyName={company.name} imgUrl={company.logo_url} />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>

      <Tabs tabs={tabs}></Tabs>

      <Dialog
        open={dialog === "settingsform"}
        onClose={() => setDialog(null)}
        title="Company Settings"
      >
        <EditCompany setDialog={setDialog} />
      </Dialog>
    </>
  )
}
