import React, {useEffect, useState} from "react"
import {Autocomplete, FormControlLabel, Grid, Radio, RadioGroup, TextField} from "@mui/material"
import Typography from "@mui/material/Typography"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Container from "../../../../../../../../components/Container"
import {useTracFloSelector} from "../../../../../../../../store/useTracFloSelector"
import {ListOption} from "../../../../../../../../types/form"
// @ts-ignore
import Button from "../../../../../../../../components/Button"
import {getProjectIntegrationChangeEvents} from "../../../../../../../../api/integration"
import {
  ChangeEventDetailsFormOutput,
  PushToChangeEventType,
  formIsValid,
  pushToChangeEventTypes
} from "./ChangeEventDetails.data"


interface ChangeEventDetailsFormProps {
  projectId: string,
  onSubmit: (formOutput: ChangeEventDetailsFormOutput) => Promise<void>,
}

export default function ChangeEventDetailsForm({projectId, onSubmit}: ChangeEventDetailsFormProps) {
  const {t} = useTranslation('private')
  const activeIntegration = useTracFloSelector((s) => s.user.activeIntegration)
  const [pushToChangeEventType, setPushToChangeEventType] = useState<PushToChangeEventType>('new')
  const [selectedChangeEvent, setSelectedChangeEvent] = useState<ListOption<string> | null>(null)
  const [changeEvents, setChangeEvents] = useState<ListOption<string>[] | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (activeIntegration != null && pushToChangeEventType === 'existing') {
      getProjectIntegrationChangeEvents(activeIntegration.id, projectId).then((res) => {
        if (res.data?.change_events?.length != null) {
          setChangeEvents(res.data.change_events.map((ce) => ({
            value: ce.agave_id,
            label: `${ce.name}${ce.number ? ` (#${ce.number})` : ''}`
          })))
        }
      })
    }
  }, [projectId, activeIntegration, pushToChangeEventType,])

  const selectPushToChangeEventType = (_: any, selectedPushToChangeEventType: string): void => {
    if (pushToChangeEventTypes.includes(selectedPushToChangeEventType)) {
      // Reset related inputs
      setSelectedChangeEvent(null)
      setChangeEvents(null)
      setPushToChangeEventType(selectedPushToChangeEventType as PushToChangeEventType)
    }
  }

  const selectChangeEvent = (_: any, selectedChangeEvent: ListOption<string> | null): void => {
    setSelectedChangeEvent(selectedChangeEvent)
  }

  const submitChangeEventDetails = async (): Promise<void> => {
    if (formIsValid(pushToChangeEventType, selectedChangeEvent)) {
      setIsSubmitting(true)
      await onSubmit({existingChangeEvent: selectedChangeEvent})
      setIsSubmitting(false)
    }
  }

  const loadingChangeEventsMessage = <Typography variant="body1">
    {t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.loading")}
  </Typography>
  const noChangeEventsMessage = <Typography variant="body1">
    {t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.noChangeEvents")}
  </Typography>

  return (
    <Container>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={6} md={7}>
          <Typography variant="h1">
            {t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.title")}
          </Typography>
        </Grid>

        <Grid container item xs={10} md={10} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">
              {t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.selectPushToChangeEventType")}
            </Typography>
            <RadioGroup onChange={selectPushToChangeEventType} value={pushToChangeEventType}>
              <FormControlLabel
                control={<Radio/>}
                label={t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.newChangeEventType")}
                value={'new'}
              />
              <FormControlLabel
                control={<Radio/>}
                label={t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.existingChangeEventType")}
                value={'existing'}
              />
            </RadioGroup>
          </Grid>

          {pushToChangeEventType === 'existing'
            ? <Grid item xs={12}>
              {changeEvents == null
                ? loadingChangeEventsMessage
                : changeEvents.length === 0
                ? noChangeEventsMessage
                : <Autocomplete<ListOption<string> | null>
                  onChange={selectChangeEvent}
                  autoComplete
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      label={t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.changeEventListLabel")}
                      variant={'standard'}
                      {...params}
                    />
                  )}
                  options={changeEvents}
                />
              }
            </Grid>
            : <></>
          }

          <Grid container justifyContent="flex-end" item>
            <Button
              disabled={!formIsValid(pushToChangeEventType, selectedChangeEvent) || isSubmitting}
              onClick={submitChangeEventDetails}
            >
              {t("view.ChangeOrder.Integration.ProcorePushToChangeEvent.ChangeEventDetailsForm.submitButton")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
