import React from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from 'notistack'

import {ChangeEventDetailsFormOutput,} from "./ChangeEventDetails.data"
import ChangeEventDetailsForm from './ChangeEventDetails'
import {useTracFloSelector} from '../../../../../../../../store/useTracFloSelector'
import {
  addTfLineItemsToChangeOrder,
  connectChangeEvent,
  createAgaveChangeEvent,
} from '../../../../../../../../api/changeOrderIntegration'


interface PushToProcoreChangeEventProps {
  projectId: string,
  changeOrder: any,
  closeDialog: () => void
}

export default function PushToProcoreChangeEvent({projectId, changeOrder, closeDialog}: PushToProcoreChangeEventProps) {
  const {t} = useTranslation('private')
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const activeIntegration = useTracFloSelector((s) => s.user.activeIntegration)

  const onSubmit = async (ceDetails: ChangeEventDetailsFormOutput) => {
    if (activeIntegration == null) return
    const connectingSb = enqueueSnackbar(
      t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.connectingChangeEvent'),
      {variant: 'info', persist: true}
    )
    try {
      const createNew = ceDetails.existingChangeEvent == null
      const connectRes = createNew
        ? await createAgaveChangeEvent(activeIntegration.id, projectId, changeOrder.id)
        : await connectChangeEvent(activeIntegration.id, projectId, changeOrder.id, ceDetails.existingChangeEvent?.value as string)
      closeSnackbar(connectingSb)
      if (!!connectRes?.data?.id) {
        // if creating new, then we are done
        if (createNew) {
          enqueueSnackbar(t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.pushSuccessful'), {variant: 'success'})
          closeDialog()
          return
        }
        // otherwise we need to push line items
        const pushingLis = enqueueSnackbar(
          t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.pushingLineItems'),
          {variant: 'info', persist: true}
        )
        try {
          const pushRes = await addTfLineItemsToChangeOrder(connectRes.data.id, null, null)
          if (pushRes?.data?.success) {
            closeSnackbar(pushingLis)
            enqueueSnackbar(t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.pushSuccessful'), {variant: 'success'})
          } else {
            closeSnackbar(pushingLis)
            enqueueSnackbar(t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.issuePushingLineItems'), {variant: 'error'})
          }
        } catch {
          closeSnackbar(pushingLis)
          enqueueSnackbar(t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.issuePushingLineItems'), {variant: 'error'})
        }
      } else {
        enqueueSnackbar(t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.issueConnectingCos'), {variant: 'error'})
      }
      closeDialog()
    } catch {
      closeSnackbar(connectingSb)
      enqueueSnackbar(t('view.ChangeOrder.Integration.ProcorePushToChangeEvent.Submit.issueConnectingCos'), {variant: 'error'})
      closeDialog()
    }
  }

  return (
    <ChangeEventDetailsForm projectId={projectId} onSubmit={onSubmit}/>
  )
}
